<template>
  <div
    da-expose-code="cart-item-block"
    class="j-cart-item cart-soldout-swiper"
    :class="[
      { 'sold-out': soldoutIds.indexOf(item.id) > -1 },
      { 'not-platform-item': !isPlatform && !showThreeStore },
      {'cart-soldout-swiper_max-pb': isShowInputNumberTips}
    ]"
    :sku="item.product.goods_sn"
    :data-cart-id="item.id"
  >
    <template v-if="!saveOrDeleted">
      <ul
        class="cart-row"
        :data-goods-id="item.product.goods_id"
        :data-sku-id="item.product.goods_sn"
        :data-spu-id="item.product.productRelationID"
        :data-index="index"
        :data-us-price="item.product.feSalePrice.usdAmount"
        :data-us-origin-price="item.product.feOriginalPrice.usdAmount"
        :data-attr-stock="item.attr && item.attr.stock"
        :data-mall-code="item.mall_code"
        :data-insurable="item.product.insurable"
        :da-event-expose="isBatchActivePage? '' : '1-7-3-6'"
        :data-unuse-coupon="isShowNoCouponUse(item)"
        :data-goods-name-before-label-id="goodsNameBeforeLabel.labelId"
      >
        <li class="cart-img">
          <div class="cart-img-gd">
            <slot></slot>
            <cart-list-item-img
              :item="item"
              :is-first-page="index < 6"
              :goods-detail-url="goodsDetailUrl"
              :hide-corner="isNewCartDiscountStyle"
              @click-goods-list="clickGoodsList"
            />
          </div>
        </li>
        <li class="cart-info">
          <a
            class="gd-name"
            :tabindex="isBatchActivePage ? -1 : 0"
            :title="item.product.goods_name"
            @click="clickGoodsTitle()"
          >
            <img
              v-if="goodsNameBeforeLabel.icon"
              class="gd-name__before-label"
              :src="
                transformImg({
                  img: goodsNameBeforeLabel.icon,
                })
              "
            />
            <span class="gd-name__text">{{ item.product.goods_name }}</span>
          </a>
          <cart-list-attr-btn 
            :item="item"
            :index="index"
          />
          <!-- 空的div是grid布局中间空间占位符 -->
          <div></div>
          <CartItemPriceBlock
            :item="item"
            :index="index"
            :limit-num="itemLimitNum"
            :scroll-parent="scrollParent"
            :show-discount-tag="showDiscountTag"
            :discount-text="discountText"
            @show-input-number-tips="(v) => isShowInputNumberTips = v"
            @del-or-save-opt="delOrSaveOpt"
          />
        </li>
      </ul>
    </template>
    <!-- 删除动画 -->
    <div
      v-show="saveOrDeleted"
      class="cart-item__remove-tip"
      :class="{'removed': [3].indexOf(saveOrDeleted) > -1}"
    >
      <div>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import cartListItemImg from '../cartListItemImg.vue'
import cartListAttrBtn from '../newList/CartItemAttrBtn.vue'
import CartItemPriceBlock from './CartItemPriceBlock.vue'
import { daEventCenter } from '../../../../../services/eventCenter'
import { handleOrderOrSingleIsMeetOrOver, getDiscountText } from 'public/src/pages/cart_new/utils/index.js'
import { transformImg } from '@shein/common-function'
import { getProductDetailUrl } from 'public/src/pages/common/utils/index.js'

daEventCenter.addSubscriber({ modulecode: '1-7-3' })
const daEventExpose = daEventCenter.getExposeInstance()

const { forceHttp } = gbCommonInfo
export default {
  name: 'CartSoldOutItem',
  components: {
    cartListItemImg,
    cartListAttrBtn,
    CartItemPriceBlock
  },
  props: {
    item: {
      type: Object,
      default(){
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    },
    itemPromotionGroupInfo: {
      type: Object,
      default(){
        return {}
      }
    },
    scrollParent: {
      type: String,
      default: ''
    },
    isBatchActivePage: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      saveOrDeleted: 0,
      isShowInputNumberTips: false,
      goodsDetailUrl: '',
      isSafari: false,
    }
  },
  computed: {
    ...mapState(['language', 'isPlatform', 'ABTParams', 'soldoutIds', 'mallShippingInfo', 'integrityPromotionInfo', 'blackFridayImgGoods', 'locals']),
    ...mapGetters(['singleMallCode', 'flashSaleGroupList', 'showThreeStore', 'underPriceGroupList', 'isNewCartDiscountStyle']),
    promotionGroupInfo(){
      let groupPromotion = this.item.product.product_promotion_info?.find(promotion => promotion.type_id == 10 || promotion.type_id == 30) // 常规闪购活动支持跨店铺or一口价
      if(groupPromotion) {
        let groupName = groupPromotion.type_id == 10 ? 'flashSaleGroupList' : 'underPriceGroupList'
        let groupPro = this.integrityPromotionInfo[groupPromotion.promotion_id] || {}
        return { ...groupPro, list: this[groupName][groupPromotion.promotion_id] || [] }
      }
      return this.itemPromotionGroupInfo
    },
    /**
     * 单品或订单限购满足数量或超过的情况
     * isOrderOrSingleMeetOrOver 是否单品或订单限购满足数量或超过，是则置灰添加按钮
     * itemLimitNum 商品行限购数量
     */
    orderOrSingleMeetOrOverResult(){
      const { result, itemLimitNum } = handleOrderOrSingleIsMeetOrOver(this.item, this.promotionGroupInfo, false, true)
      return {
        isOrderOrSingleMeetOrOver: Boolean(result),
        itemLimitNum
      }
    },
    // 商品行限购数量 0：不限购
    itemLimitNum(){
      let promotion = this.item.product_promotion_info?.find(item => item.type_id == 3)
      if (promotion) {
        return promotion.cart_limit?.single || 0
      } else {
        return this.orderOrSingleMeetOrOverResult.itemLimitNum
      }
    },
    showNoFreeShipping () {
      if(this.isPlatform){
        let result = 0
        let platformActivityType =  this.mallShippingInfo[`platform`]?.activity_type || ''
        result = (platformActivityType == 1 && this.item.free_activity_limit == 1) || (platformActivityType == 2 && this.item.free_policy_limit == 1) || (platformActivityType == 3 && this.item.freight_activity_limit == 1)
        if(result){
          return 1
        }
        let shopOrSelfActivityType = this.mallShippingInfo[this.item.mall_code]?.activity_type || ''
        result = (shopOrSelfActivityType == 1 && this.item.free_activity_limit == 1) || (shopOrSelfActivityType == 2 && this.item.free_policy_limit == 1) || (shopOrSelfActivityType == 3 && this.item.freight_activity_limit == 1)
        return result ? 2 : 0
      }
      let actType = this.mallShippingInfo[this.singleMallCode]?.activity_type || ''
      return (actType == 1 && this.item.free_activity_limit == 1) || ( actType == 2 && this.item.free_policy_limit == 1) || (actType == 3 && this.item.freight_activity_limit == 1) ? 2 : 0
    },
    getActivityType(){
      if(this.showNoFreeShipping){
        return this.showNoFreeShipping == 1 ? this.mallShippingInfo[`platform`]?.activity_type : this.mallShippingInfo[this.item.mall_code]?.activity_type
      }
      return ''
    },
    goodsNameBeforeLabel() {
      return this.blackFridayImgGoods[this.item?.product?.goods_id]?.cartPcStandardView?.goodsNameLabel || {}
    },
    // 当前商品行最大折扣信息
    maxDiscount() {
      if (this.item.isGiftItem) return 0
      const { unit_discount = 0, sale_discount = 0 } = this.item?.product
      return Math.max(Number(unit_discount), Number(sale_discount))
    },
    showDiscountTag() {
      return this.isNewCartDiscountStyle && this.maxDiscount != 0
    },
    discountText() {
      const { lang, GB_cssRight } = this.locals
      return getDiscountText(lang, GB_cssRight, this.maxDiscount)
    },
  },
  mounted() {
    this.initExpose()
    if (navigator && navigator.userAgent) {
      this.isSafari = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome') ? true : false
      if (this.isSafari) {
        this.gotoDetail()
      }
    }
  },
  methods: {
    /**
     * @description 不可参与优惠券提示
        1、自营商品
        2、have_tag为1命中
        3、abt返回on
     * @param {*} item 购物车商品属性
     */
    isShowNoCouponUse (item) {
      let flag = 'unused_coupon_tip_0'
      if (item?.store_type != 2) return flag
      if (item?.product?.have_tag == 1) {
        flag = `unused_coupon_tip_${
          this.ABTParams?.['Cartnoteligibleforcoupons']?.param == 'on'
            ? 1
            : 2
        }`
      }
      return flag
    },
    async gotoDetail() {
      const {
        item: {
          mall_code,
          product: { goods_img, goods_url_name, goods_id, cat_id },
        }
      } = this
      const url = await getProductDetailUrl({
        imgSrc: goods_img,
        goods_url_name,
        goods_id,
        cat_id,
        mall_code,
      })
      this.goodsDetailUrl = url
      return
      // window.open(forceHttp + url, '_blank')
    },
    clickGoodsTitle() {
      if (this.item.isSheinClubGiftItem) {
        this.$toast(this.item.isInvalid ? (this.item.promotionTag?.promotionLogoType === 14 ? this.language.SHEIN_KEY_PC_30893 : this.language.SHEIN_KEY_PC_31013) : this.language.SHEIN_KEY_PC_30889)
        return
      }
      this.clickGoodsList()
      if (this.isBatchActivePage) return
      if(this.isSafari && this.goodsDetailUrl){
        window.open(forceHttp + this.goodsDetailUrl, '_blank')
      } else {
        this.gotoDetail().then(() => {
          window.open(forceHttp + this.goodsDetailUrl, '_blank')
        })
      }
    },
    clickGoodsList(){
      if (this.isBatchActivePage) return
      daEventCenter.triggerNotice({
        daId: '1-7-3-3',
        extraData: {
          item: this.item,
          index: this.index,
          goodsNameBeforeLabelId: this.goodsNameBeforeLabel.labelId,
        }
      })
    },
    delOrSaveOpt(saveOrDeleted, cb = () => {}){
      this.saveOrDeleted = saveOrDeleted
      setTimeout(() => {
        this.saveOrDeleted = 3
        setTimeout(() => {
          cb()
        }, 500)
      }, 50)
    },
    initExpose() {
      this.$nextTick(()=>{
        daEventExpose.subscribe && daEventExpose.subscribe({
          keycode: 'cart-item-block`1-7-3-6|1-7-3-4|1-7-5-1|1-7-5-9|1-7-5-21|1-7-5-18|1-7-5-19',
        })
      })

    },
    transformImg,
  }
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-max-specificity */
// 单个售罄商品改变了布局方案,不可添加table class 需单独设置padding
.cart-soldout-swiper {
  color: #222222;
  padding: 8px 16px;
  background-color: #FFFFFF;
  position: relative;
  &.not-platform-item + &.not-platform-item{
    &:before{
      content: '';
      width: calc(~"100% - 48px");
      display: block;
      top: 0;
      position: absolute;
      border-bottom: 1px dashed #e5e5e5;
    }
  }
  &_max-pb{
    padding-bottom: 16px;
  }
  .cart-img-gd{
    display: flex;
  }
  .cart-img__gd-footer{
    color: #767676;
    font-size: 12px;
    padding-top: 13px;
    padding-left: 44px;
    .tip {
      display: flex;
      align-items: center;
    }
    i{
      color: #999;
      .margin-r(5px)
    }
    .tip + .tip{
      margin-top: 4px;
    }
    .extra-point-tip{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
      position: relative;
      text-overflow: -o-ellipsis-lastline;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  a.gd-name{
    color: #222222 /*rw: #959595*/;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    &:hover{
      font-weight: bold;
      text-decoration: none;
    }
    &:focus{
      text-decoration: none;
    }
    .gd-name__before-label {
      margin-right: 4px;
      width: 48px;
      height: 12px;
      object-fit: contain;
    }
    .gd-name__text {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &.sold-out{
    .gd-name, :deep(.goods-price-red),.cart-total{
      opacity: .3;
    }
  }
  .only-left{
    color: #FE3B30;
    font-size: 13px;
    text-align: left;
    margin-top: 8px;
  }
}
.cart-item__remove-tip{
  padding: 89px 0;
  padding: 0;
  font-size: 14px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #222222;
  line-height: 16px;
  text-align: center;
  height: 136px;
  transition: height 0.5s ease-in-out;
  i{
    color: @sui_color_safety;
    font-size: 16px;
    vertical-align: bottom;
    .margin-r(4px);
  }
  &.removed{
    height: 0;
  }
}
.cart-info {
  flex: 1 1 auto;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  min-height: 120px;
}
.cart-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.cart-img {
  margin-right: 16px;
  flex: 0 0 auto;
}
.cart-list__attr-block {
  margin-top: 8px;
}
</style>
