import * as api from './api'
import { HtmlCacheClass } from 'public/src/js/utils/htmlCacheClass'
import { getToCheckoutUrl } from 'public/src/pages/cart_new/utils/getToCheckoutUrl'
import { shippingCenter } from 'public/src/pages/common/shipping'
import { promotionCenterNew } from 'public/src/pages/common/promotion/indexNew.js'
import { handlerCartsPromotionGroup, getGroupType, getStoreItemByStoreCode } from '../utils/handler'
import { getFilterLabelsByCartItem, composeFilterLabels } from '../utils/cartFilterLabels.js'
import blackFridayImg from '../utils/blackFridayImg'
import { SMessage } from '@shein-aidc/sui-message'
import { daEventCenter } from 'public/src/services/eventCenter'
import { getTaxTip } from '../utils/fetcher'
import { shuffleArr, isThirtyLowestPrice, isValidCartItem } from '../utils/index.js'
import schttp from 'public/src/services/schttp'

import BehaviorLabel from 'public/src/pages/cart_new/utils/behaviorLabel.js'
import md5 from 'md5'
import { uniqBy } from 'lodash'
import { template, isEmpty } from '@shein/common-function'
import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index.js'
import { getQsTimelinessInfo } from '@/public/src/pages/cart_new/utils/qsTagInfo.js'
import { getXLowestPopoverTips, floorToNearestBase } from 'public/src/pages/cart_new/components/cartCheckoutIncentive/incentiveTools.js'

const HtmlCacheClassInstance = HtmlCacheClass.getInstance()
/**
 * 计算跳转下单页的url
 * @param {prime_product_code} 似乎已不用 
 * @param {coupon_infos} 优惠券hash值 
 */
const prefetchHTML = (props) => {
  let source = getToCheckoutUrl(props)
  const carts = props?.carts
  const prefetchFlag = Array.isArray(carts) && carts?.length > 0 && carts.some(item => item?.is_checked === 1)
  source && HtmlCacheClassInstance.loopFn({ source, carts, prefetchFlag })
}

const actions = {
  async fetchMallShippingInfo({ state, commit }, payload) {
    const shippingContentInfo = await shippingCenter.getMallShippingContent({
      mall: payload.shippingInfoParams,
      shippingInfo: payload.shippingInfo || null,
    })
    commit('updateField', {
      key: 'mallShippingInfo',
      value: shippingContentInfo,
    })
    commit('updateField', {
      key: 'callShippingFreeMallCount',
      value: state.callShippingFreeMallCount + 1,
    })

    commit('updateBottomActivityConfig', { freeshipIsReady: true })
  },
  fetchSSRMallShippingInfo({ commit }, payload) {
    const shippingContentInfo = shippingCenter.handleShippingContent(payload.shippingInfo || null)
    commit('updateField', {
      key: 'mallShippingInfo',
      value: shippingContentInfo,
    })

    commit('updateBottomActivityConfig', { freeshipIsReady: true })
  },
  async fetchStoreSelects({ commit }, payload) {
    if(typeof window == 'undefined') return
    const storeSelectsInfo = await api.matchStoreJumpUrl(payload)
    commit('setStoreSelectsInfo', storeSelectsInfo)
  },
  async fetchModifyCartCheckStatus({ commit, dispatch, state }, payload) {
    commit('updateField', { key: 'bigLoading', value: true })
    let editCartId = payload.editCartId
    const result = await api.modifyCartCheckStatus(payload)
    if (result.code == 0) {
      let originCarts = JSON.parse(JSON.stringify(state.cartList))
      await dispatch('asyncUpdateCartInfo', { cartInfo: result.info })
      dispatch('handleFlashSaleOverScene', {
        editCartId: editCartId || '',
        carts: result.info?.carts,
        originCarts,
      })
    }
    commit('updateField', { key: 'bigLoading', value: false })
    return result
  },
  // 所有需要接口返回，再去更新视图，不然会出现页面闪动的情况
  async asyncUpdateCartInfo(
    { commit, dispatch, state, getters },
    { cartInfo, shippingInfo, isSSR }
  ) {
    typeof window !== 'undefined' && commit('updateLocals', { USER_LOCAL_SIZE_COUNTRY: window.localStorage.getItem('last_select_country') || '' })
    // SSR中会调用，需要判断在客户端使用就行
    typeof window !== 'undefined' && prefetchHTML({ prime_product_code: '', coupon_infos: cartInfo?.coupon_infos, carts: cartInfo?.carts })
    // change root state
    commit('updateCartInfo', { cartInfo: cartInfo })
    // commit('updateCartList', {cartList: cartInfo.carts || []})
    commit('updateField', {
      key: 'cartSumQuantity',
      value: cartInfo.cartSumQuantity || 0,
    })
    commit('updateField', { key: 'mallList', value: cartInfo.mall_list || [] })
    if (!state.originCartNum) {
      commit('updateField', {
        key: 'originCartNum',
        value: cartInfo.cartSumQuantity || 0
      })
    }
    dispatch('handleMallShippingInfo', { cartInfo, shippingInfo, isSSR })
    dispatch('handleStoreInfo', cartInfo)
    dispatch('fetchBlackFridayImgInfo', cartInfo)
    console.log('Done! change cart info.')
    let integrityPromotionInfo = {}
    if(isSSR) {
      // 购物车更新重置（底部全场活动更新需要3种数据都准备好）
      commit('updateBottomActivityConfig', {
        couponIsReady: true,
        freeshipIsReady: true,
      })
      const promotionInfo = promotionCenterNew.handleSSRIntegrityPromotionInfo({
        res: state.cartIntegrityPromotionInfo,
        language: state.language,
        goods: cartInfo.carts || [],
        promotion_range: cartInfo.promotion_range,
        locals: state.locals,
        isSuggested: getters.isSuggested,
      })
      integrityPromotionInfo = promotionInfo.integrityPromotionInfo
    } else {
      // 购物车更新重置（底部全场活动更新需要3种数据都准备好）
      commit('updateBottomActivityConfig', {
        couponIsReady: false,
        freeshipIsReady: false,
      })
      const promotionInfo =  await promotionCenterNew.preHandlePromotion({
        language: state.language,
        goods: cartInfo.carts || [],
        promotion_range: cartInfo.promotion_range,
        isSuggested: getters.isSuggested
      })
      integrityPromotionInfo = promotionInfo.integrityPromotionInfo
    }
    
    
    commit('updateIntegrityPromotionInfo', integrityPromotionInfo)

    dispatch('checkSoldOut', { cartInfo: cartInfo })

    dispatch('handleFullGiftPromotionType')
    dispatch('updatePromotionRuleList', { promotionInfo: state.integrityPromotionInfo })
    dispatch('handleCartInducementInfo')
  },
  checkSoldOut(
    { commit, dispatch, state, getters },
    { cartInfo = null, outStockCarts = null }
  ) {
    let soldoutItems = []
    // 其他mall可售商品
    const getPrioritySwitchMallList = (item) => {
      // 当前商品可售的MALL
      const saleMallList = item.new_mall_code_list?.filter(mall => mall.mall_code != item.mall_code) ?? []
      // 当前商品有库存的MALL
      const stockMallList = item.stock_mall_list?.filter(mall => mall.mall_code != item.mall_code) ?? []
      // 可销售且有库存的MALL
      const saleStockMallList = stockMallList?.filter(item => saleMallList.includes(item.mall_code)) ?? []
      // 站点基础MALL信息优先级排序
      const siteMallListSorted = state.mallInfo?.sort((a, b) => a.mall_sort - b.mall_sort) ?? []
      // 最高优先级可切MALL
      const prioritySwitchMallList = saleStockMallList?.sort((a, b) => {
        return siteMallListSorted?.indexOf(a.mall_code) - siteMallListSorted?.indexOf(b.mall_code)
      })

      return prioritySwitchMallList?.[0]
    }
    const isMallSwitchGoods = (item) => {
      return !item.isCustomGood && !item.isGiftItem && !item.isAddItem && !!getPrioritySwitchMallList(item)
    }
    // 可售属性切换商品
    // CartItemAttrBtn.vue 内部逻辑迁移过来
    const isSaleAttrSwitchGoods = (item, isPlatform) => {
      if (!isValidCartItem(item, isPlatform)) return false
      const isAddBuyGoodsOrGiftGoods = (item) => item.product.product_promotion_info && item.product.product_promotion_info.some(promo => [1, 2].includes(+promo.promotion_product_type))
      return item.isSheinClubGiftItem && !item.isInvalid ? true : !isAddBuyGoodsOrGiftGoods(item) && !item.isLoseNewcomerItem
    }
    if (outStockCarts) {
      soldoutItems = state.soldoutItems
      let outStockCartsId = outStockCarts.map((item) => item.id)
      const soldoutCarts = state.cartList.filter(
        (item) => outStockCartsId.indexOf(item.id) > -1
      )
      soldoutItems = soldoutItems.concat(soldoutCarts)
      commit('updateField', { key: 'outStockCarts', value: soldoutCarts })
    } else if (cartInfo) {
      // 售罄商品
      const isSoldOutItem = (item) => {
        return item?.product?.is_on_sale == 0 
        || item?.realTimeInventory == 0 
        || item.status == 1 
        || (item.status == 2 && [0, 2, 3, 4, 18, 20, 22 ].includes(+item.promotion_status)) 
        || item.status == -1 
        || (item.status == 2 && [ 11, 13, 14, 15, 16 ].includes(+item.promotion_status)) 
        || item.promotion_status === 21
      }
      
      // 购物车内所有告罄商品
      soldoutItems = cartInfo?.carts?.filter((item) => isSoldOutItem(item)) || []
    }
    // 失效商品组商品展示顺序: 其他MALL可售(多mall)>其他颜色和尺码可售>剩余的失效商品
    // wiki.pageId=900641894
    soldoutItems.forEach((item) => {
      item.isMallSwitchGoods = isMallSwitchGoods(item)
      item.isSaleAttrSwitchGoods = isSaleAttrSwitchGoods(item, state.isPlatform)
      item.prioritySwitchMall = getPrioritySwitchMallList(item)
      if (item.status == 2 && [ 11, 13, 14, 15, 16 ].includes(+item.promotion_status)) {
        // 失去【新人专享价】资格
        item.isLoseNewcomerItem = true
      }
      if (item.promotionTag?.typeId === 4 && [8, 14].includes(item.promotionTag.promotionLogoType)) {
        // 付费会员赠品
        item.isSheinClubGiftItem = true
        item.isInvalid = !getters.isSheinClubMember
      }
      let mall_code_list =
        item.mall_code_list?.filter((mallItem) => mallItem != item.mall_code) ||
        []
      let isGiftOrAddOn = item.product.product_promotion_info?.some((promo) =>
        [1, 2].includes(+promo.promotion_product_type)
      )
        ? true
        : false
      if (isGiftOrAddOn || (item.status == -1 && !state.isPlatform)) {
        // 附属品以及当前单mall站点该mall商品不存在则不支持修改属性，直接展示'找相似'，故优先级最低
        item.soldoutPriority = 0
      } else if (
        state.isPlatform &&
        mall_code_list.length &&
        (item.status == 1 || item.status == -1)
      ) {
        // 多mall站点 商品失效，其他mall可售
        item.soldoutPriority = 2
      } else {
        const hasRelatedProductIds = item.relatedProductIds?.length
          ? true
          : false // 有关联商品
        const noMainSaleAttrs =
          (item.product.sku_sale_attr &&
            item.product.sku_sale_attr.filter(
              (item) => !item.is_main && item.attr_value_id
            )) ||
          [] // 新sku属性结构 过滤掉主属性后的尺码 attr_value_id ==0为无尺码 反之为有尺码
        let oldSingleOrOneSize =
          !item.attr ||
          item.attr?.attr_value_en == 'one-size' ||
          item.attr?.attr_value_id === 0 // 旧sku属性结构 判断单尺码或无尺码
        const noStockOrOnSale =
          !hasRelatedProductIds &&
          ((item.product.stock == 0 &&
            +item.realTimeInventory < +item.quantity) ||
            item.product.is_on_sale == 0)
        if (
          hasRelatedProductIds ||
          (!noStockOrOnSale && !oldSingleOrOneSize && noMainSaleAttrs.length)
        ) {
          item.soldoutPriority = 1
        } else {
          item.soldoutPriority = 0
        }
      }
    })
    soldoutItems.sort((a, b) => {
      return b.soldoutPriority - a.soldoutPriority
    })
    const soldoutIds = soldoutItems.map((item) => item.id) // 购物车内告罄商品id
    commit('updateField', { key: 'soldoutIds', value: soldoutIds })
    // 根据 ABT 剔除满足 isMallSwitchGoods 和 isSaleAttrSwitchGoods 的商品
    const filterSoldOutItems = soldoutItems.filter((item) => !((getters.cartMallSwitchGoods && item.isMallSwitchGoods) || (getters.cartSaleAttrSwitchGoods && item.isSaleAttrSwitchGoods)))
    commit('updateField', { key: 'soldoutItems', value: filterSoldOutItems })

    console.log('Done! handle sold out.')

    typeof window !== 'undefined' && commit('updateField', { key: 'lastCheckStockTime', value: Date.now() })

    if (!getters.showOBMStore  && !getters.showThreeStore) {
      dispatch('handlerCartListInfo', cartInfo?.carts)
    } else {
      dispatch('handleMallListInfo', cartInfo?.carts)
    }
    dispatch('handleFlashSaleOverLimit')
  },
  /**
   *
   * @param commit
   * @param state
   * @param cartsList
   * mallCartList : [{
   *   mall_code:xxx,
   *   mall_list:[
   *   {
   *    store_code:xxxx,
   *    store_title:xxx,
   *    store_list:[],
   *    store_list_origin:[]
   *   }
   *  ]
   * }]
   */
  handlerCartListInfo({ commit, state, getters, dispatch }, cartsList) {
    let carts = cartsList ? cartsList : state.cartList
    const normalOnSaleCartList = carts.filter(
      (item) => !state.soldoutItems.map(item => item.id).includes(item.id)
    ) // 正常在售商品
    let mallCartList = []
    let promotionLabels = []
    let doFilter = normalOnSaleCartList.length > 4
    normalOnSaleCartList.forEach((cartItem) => {
      let mallCart = mallCartList.find(
        (obj) => obj.mall_code === cartItem.mall_code
      )
      let isShopCart = getters.showThreeStore && cartItem.business_model == 1 // 实验展示第三方店铺组头且是第三方店铺商品
      if (!mallCart) {
        mallCart = {
          mall_code: cartItem.mall_code,
          mall_list: [],
        }
        mallCartList.push(mallCart)
      }
      let store_code = isShopCart ? cartItem.store_code : 'shein'
      let storeCart = mallCart.mall_list.find(
        (mallItem) => mallItem.store_code === store_code
      )
      if (storeCart) {
        storeCart.store_list_origin.push(cartItem)
        storeCart.preferred_seller_store =
          cartItem.preferred_seller_store == 1
            ? 1
            : storeCart.preferred_seller_store
      } else {
        let storeItem = {
          store_code: isShopCart ? cartItem.store_code : 'shein',
          storeCode: cartItem.store_code,
          store_title: isShopCart ? cartItem.store_title : 'shein',
          store_logo: isShopCart ? cartItem.store_logo : '',
          store_type: cartItem.store_type,
          preferred_seller_store: cartItem.preferred_seller_store,
          store_list: [],
          store_list_origin: [],
        }
        storeItem.store_list_origin.push(cartItem)
        mallCart.mall_list.push(storeItem)
      }
      if(doFilter) promotionLabels.push(...getFilterLabelsByCartItem(cartItem, state.language, getters.showQuickShipTag, state.cartQsTagInfo))
    })
    let normal_cart_list = []
    Object.keys(state.integrityPromotionInfo).forEach(promotionId => {
      state.integrityPromotionInfo[promotionId].list = state.integrityPromotionInfo[promotionId].list.flat()
    })
    mallCartList.forEach((mallItem) => {
      mallItem.mall_list.forEach((storeItem) => {
        storeItem.store_list = handlerCartsPromotionGroup({
          goods: storeItem.store_list_origin,
          integrityPromotionInfo: state.integrityPromotionInfo,
        }, {
          cartStoreFreegift: getters.cartStoreFreegift
        })
        storeItem.store_list.forEach((item) => {
          item.list = item.list.flat()
          normal_cart_list = normal_cart_list.concat(item.list)
        })
      })
    })
    commit('updateField', { key: 'mallCartList', value: mallCartList })
    let cartsData = [].concat(normal_cart_list, state.soldoutItems)
    commit('updateField', { key: 'promotionLabels', value: uniqBy(promotionLabels, i => i.type) })
    dispatch('handleCartFilterLabels')
   
    commit('updateCartList', { cartList: cartsData })
    let cartsId = cartsData.map((item) => item.id)
    let cartItemMap = cartsData.reduce((acc, cur) => {
      acc[cur.id] = cur
      return acc
    }, {})
    commit('updateField', { key: 'cartsId', value: cartsId })
    commit('updateField', { key: 'cartItemMap', value: cartItemMap })
    console.log('set cart data')
  },
  handleMallListInfo({ commit, state, getters, dispatch }, cartsList) {
    let carts = cartsList ? cartsList : state.cartList
    const normalOnSaleCartList = carts.filter(item => !state.soldoutItems.map(item => item.id).includes(item.id)) // 正常在售商品
    let mallCartList = []
    let promotionLabels = []
    let doFilter = normalOnSaleCartList.length > 4
    normalOnSaleCartList.forEach(cartItem => {
      let mallCart = mallCartList.find(obj => obj.mall_code === cartItem.mall_code)
      if ( !mallCart ) {
        mallCart = {
          mall_code: cartItem.mall_code,
          mall_list: [],
          mall_list_origin: [],
        }
        mallCartList.push(mallCart)
      }
      mallCart.mall_list_origin.push(cartItem)

      let { groupType, specialGroup } = getGroupType(cartItem, state.integrityPromotionInfo, {
        cartStoreFreegift: getters.cartStoreFreegift
      })
      let SheinStore = !getters.showOBMStore && getters.showThreeStore && cartItem.business_model == 0 // 不展示OBM店铺组头,且展示第三方店铺组头且是自营商品, 属于店铺shein
      if(groupType.storeLevel){ // 跨店铺活动聚合
        let item_promotion_info = state.integrityPromotionInfo[cartItem.promotionGroupId]
        let targetPromotionGroup = mallCart.mall_list.find(groupItem => groupItem?.promotion_id == cartItem.promotionGroupId)
        if(targetPromotionGroup){
          /**
           * 礼品置于卡片顶
           * 2: 买赠;
           * 13: 加价购;
           */
          if (cartItem.isAddBuyItem || cartItem.isBuyGiftItem || cartItem.isPartFullGiftItem) {
            targetPromotionGroup.list[0].unshift(cartItem)
          } else {
            // targetPromotionGroup.list.push(cartItem)
            if (cartItem.isMallSwitchGoods) {
              targetPromotionGroup.list[1].push(cartItem)
            } else if (cartItem.isSaleAttrSwitchGoods) {
              targetPromotionGroup.list[2].push(cartItem)
            } else {
              targetPromotionGroup.list[0].push(cartItem)
            }
          }
        } else {
          item_promotion_info.list = [
            [],
            [],
            []
          ]
          if (cartItem.isMallSwitchGoods) {
            item_promotion_info.list[1].push(cartItem)
          } else if (cartItem.isSaleAttrSwitchGoods) {
            item_promotion_info.list[2].push(cartItem)
          } else {
            item_promotion_info.list[0].push(cartItem)
          }
          item_promotion_info.group_type = 'promotion'
          mallCart.mall_list.push(item_promotion_info)
        }
      } else if((specialGroup?.fullGiftCard || specialGroup?.addBuyAllCard) && (cartItem.isSheinClubGiftItem || cartItem.isFullGiftItem || cartItem.isAddBuyAllItem)){ // 活动4和22 聚合方式不同
        let promotionGroup = specialGroup?.fullGiftCard || specialGroup?.addBuyAllCard
        let storeCart = getStoreItemByStoreCode(mallCart, SheinStore, cartItem, {
          cartStoreFreegift: getters.cartStoreFreegift,
          promotionDetials: state.integrityPromotionInfo
        })

        storeCart.store_list_origin.unshift(cartItem)
        storeCart.preferred_seller_store = cartItem.preferred_seller_store == 1 ? 1 : storeCart.preferred_seller_store

        let innerGroup = storeCart.store_list.find(innerGroup => innerGroup?.promotion_id == promotionGroup.promotion_id)
        if(innerGroup) {
          // innerGroup.list.push(cartItem)
          if (cartItem.isSheinClubGiftItem) {
            innerGroup.list[0].unshift(cartItem)
          } else {
            if (cartItem.isMallSwitchGoods) {
              innerGroup.list[1].push(cartItem)
            } else if (cartItem.isSaleAttrSwitchGoods) {
              innerGroup.list[2].push(cartItem)
            } else {
              innerGroup.list[0].push(cartItem)
            }
          }
        } else {
          innerGroup = promotionGroup
          innerGroup.list = [
            [],
            [],
            [],
          ]
          if (cartItem.isMallSwitchGoods) {
            innerGroup.list[1].push(cartItem)
          } else if (cartItem.isSaleAttrSwitchGoods) {
            innerGroup.list[2].push(cartItem)
          } else {
            innerGroup.list[0].push(cartItem)
          }
          storeCart.store_list.unshift(innerGroup)
        }
      } else if(!groupType.innerStore){ // 普通商品按店铺聚合
        let storeCart = getStoreItemByStoreCode(mallCart, SheinStore, cartItem, {
          cartStoreFreegift: getters.cartStoreFreegift,
          promotionDetials: state.integrityPromotionInfo
        })
        storeCart.store_list_origin.push(cartItem)
        storeCart.preferred_seller_store = cartItem.preferred_seller_store == 1 ? 1 : storeCart.preferred_seller_store
        // 全店活动附属品单独分组, 放置在组顶部
        if((cartItem.isPartFullGiftItem || cartItem.isBuyGiftItem) && getters.cartStoreFreegift) {
          let innerGroup = storeCart.store_list.find(innerGroup => innerGroup.id == 'fullStoreGift')
          if(innerGroup) {
            innerGroup.list.push(cartItem)
          } else {
            storeCart.store_list.unshift({
              id: 'fullStoreGift',
              list: [ cartItem ]
            })
          }
        } else {
          let innerGroup = storeCart.store_list.find(innerGroup => innerGroup.id == 'common')
          if(innerGroup) {
            // innerGroup.list.push(cartItem)
            if (cartItem.isMallSwitchGoods) {
              innerGroup.list[1].push(cartItem)
            } else if (cartItem.isSaleAttrSwitchGoods) {
              innerGroup.list[2].push(cartItem)
            } else {
              innerGroup.list[0].push(cartItem)
            }
          } else {
            const list = [
              [],
              [],
              []
            ]
            if (cartItem.isMallSwitchGoods) {
              list[1].push(cartItem)
            } else if (cartItem.isSaleAttrSwitchGoods) {
              list[2].push(cartItem)
            } else {
              list[0].push(cartItem)
            }
            storeCart.store_list.push({
              id: 'common',
              list,
            })
          }
        }

        // 将附属品塞回全店活动详情中, 方便活动分组状态切换
        let promoDetial = storeCart?.innerUnGroupPromotions?.find(promo => promo.promotion_id == cartItem?.promotionIds?.[0])
        let havePushed = promoDetial?.list?.find(item => item.id == cartItem.id)
        if(promoDetial?.list && !havePushed) {
          promoDetial.list.push(cartItem)
        } else if (typeof promoDetial == 'object' && !havePushed) {
          promoDetial.list = [ cartItem ]
        }

      } else if(groupType.innerStore){ // 按店铺活动内聚合
        let storeCart = getStoreItemByStoreCode(mallCart, SheinStore, cartItem, {
          cartStoreFreegift: getters.cartStoreFreegift,
          promotionDetials: state.integrityPromotionInfo
        })
        storeCart.store_list_origin.push(cartItem)
        storeCart.preferred_seller_store = cartItem.preferred_seller_store == 1 ? 1 : storeCart.preferred_seller_store

        let innerGroup = storeCart.store_list.find(innerGroup => innerGroup?.promotion_id == cartItem.promotionGroupId)
        if(innerGroup) {
          /**
           * 礼品置于卡片顶
           * 2: 买赠;
           * 13: 加价购;
           */
          if (cartItem.isAddBuyItem || cartItem.isBuyGiftItem || cartItem.isPartFullGiftItem) {
            innerGroup.list[0].unshift(cartItem)
          } else {
            // innerGroup.list.push(cartItem)
            if (cartItem.isMallSwitchGoods) {
              innerGroup.list[1].push(cartItem)
            } else if (cartItem.isSaleAttrSwitchGoods) {
              innerGroup.list[2].push(cartItem)
            } else {
              innerGroup.list[0].push(cartItem)
            }
          }
        } else {
          let item_promotion_info = state.integrityPromotionInfo[cartItem.promotionGroupId]
          item_promotion_info.list = [
            [],
            [],
            [],
          ]
          if (cartItem.isMallSwitchGoods) {
            item_promotion_info.list[1].push(cartItem)
          } else if (cartItem.isSaleAttrSwitchGoods) {
            item_promotion_info.list[2].push(cartItem)
          } else {
            item_promotion_info.list[0].push(cartItem)
          }
          item_promotion_info.group_type = 'promotion'
          storeCart.store_list.push(item_promotion_info)
        }
      }
      if(doFilter) promotionLabels.push(...getFilterLabelsByCartItem(cartItem, state.language, getters.showQuickShipTag, state.cartQsTagInfo))
    })
    Object.keys(state.integrityPromotionInfo).forEach(promotionId => {
      state.integrityPromotionInfo[promotionId].list = state.integrityPromotionInfo[promotionId].list.flat()
    })
    let normal_cart_list = []
    mallCartList.forEach(mallItem => {
      mallItem.mall_list.forEach(storeLevelGroup => {
        if(storeLevelGroup.list) normal_cart_list = normal_cart_list.concat(storeLevelGroup.list)
        else {
          storeLevelGroup.store_list.forEach(group => {
            group.list = group.list.flat()
            normal_cart_list = normal_cart_list.concat(group.list)
          })
        }
      })
    })

    let cartsData = [].concat(normal_cart_list, state.soldoutItems)
    let cartsId = cartsData.map(item => item.id)
    let cartItemMap = cartsData.reduce((acc, cur) => {
      acc[cur.id] = cur
      return acc
    }, {})
    commit('updateField', { key: 'mallCartList', value: mallCartList })
    commit('updateCartList', { cartList: cartsData })
    commit('updateField', { key: 'cartsId', value: cartsId })
    commit('updateField', { key: 'cartItemMap', value: cartItemMap })
    // commit('updateField', { key: 'cartsId', value: cartsId })
    commit('updateField', { key: 'promotionLabels', value: uniqBy(promotionLabels, i => i.type) })
    dispatch('handleCartFilterLabels')
  },
  handleStoreInfo({ dispatch }, cartInfo){
    let cartList = cartInfo?.carts || []
    let stores = cartList
      .filter((item) => {
        return item.store_code != 1
      })
      .map((item) => item.store_code)
    let storeCodes = [...new Set(stores)]
    storeCodes.length && dispatch('fetchStoreSelects', storeCodes)
  },
  handleMallShippingInfo({ dispatch }, { cartInfo, shippingInfo, isSSR }) {
    if(isSSR) {
      dispatch('fetchSSRMallShippingInfo', { shippingInfo })
    } else {
      let mallList = cartInfo?.mall_list || []
      let shippingInfoParams = []
      mallList.forEach((item) => {
        let cart_list = cartInfo?.carts?.filter(
          (cartItem) =>
            cartItem.mall_code == item.mall_code && cartItem.is_checked == 1
        )
        let mallInfo = {
          mall_code: item.mall_code,
          is_free_activity_limit: item.is_free_activity_limit,
          free_shipping_product_price: item.free_shipping_product_price?.amount,
          total_price: item.total_price?.amount,
          skc_list: cart_list.map((cartItem) => cartItem.product.goods_sn),
        }
        shippingInfoParams.push(mallInfo)
      })
  
      dispatch('fetchMallShippingInfo', { shippingInfoParams })
      dispatch('getCartQsTagInfo')
    }
    
  },
  /**
   * 获取购物车quickship标签信息
   * @param {*} abtInfo 
   * @param {*} qsTimelinessInfo qs时效信息
   * @param {*} language 
   * @returns 
   */
  async getCartQsTagInfo ({ commit, state }) {
    // 判断是否是数字或者数字字符串
    const isNumberOrString = (value) => value !== '' && (typeof value === 'number' || typeof value === 'string') && !isNaN(Number(value))
    const { CartQuickshipVisual = {}, QSinformation = {} } = state.ABTParams
    const cartQsVisual = CartQuickshipVisual?.param?.cart_quickship_visual
    const isLocationTop = QSinformation?.param?.QSlocation === 'up'
    const qsTimelinessInfo = await getQsTimelinessInfo()
    const qsData = {
      // qs标签新样式
      isShowNewStyle: qsTimelinessInfo?.quickShipTextEn !== 'QuickShip' || cartQsVisual && (cartQsVisual === 'quickship' || isNumberOrString(cartQsVisual)) || false,
      // qs标签标题（前半部分内容）
      text: qsTimelinessInfo?.quickShipText,
      // qs标签时效信息（后半部分内容）
      qsTimelinessInfo: {
        isLocationTop: isLocationTop || false, // 是否在顶部展示（顶部/商品行）
        tip: qsTimelinessInfo?.info || '',
      }
    }
    commit('updateField', { key: 'cartQsTagInfo', value: qsData })
  },
  async fetchBlackFridayImgInfo({ commit, state, getters, dispatch }, cartInfo) {
    if(typeof window == 'undefined') return
    let goods = cartInfo?.carts?.map((item) => {
      return {
        goods_id: item.product.goods_id,
        goods_sn: item.product.goods_sn,
        mall_code: item.mall_code,
      }
    })
    const behaviorShow = state.ABTParams?.UserBehaviorTips?.param?.CartBehaviorShow
    const behaviorLive = state.ABTParams?.UserBehaviorTips?.param?.CartBehaviorLive
    const normalLabels = BehaviorLabel.getLabelIdsByStr(behaviorShow)
    const liveLabels = BehaviorLabel.getLabelIdsByStr(behaviorLive)
    const cart_goods_label = state.ABTParams?.CartGoodsLabel?.param?.cart_goods_label || ''
    // 筛选栏abt配置的行为标签
    const filterLabelArr = getters.filterLabelOrder.split(',')
    const filterLabels = []
    if (filterLabelArr.includes('30day_additem'))  filterLabels.push('3460')
    if (filterLabelArr.includes('30day_sale'))  filterLabels.push('3451')
    if (filterLabelArr.includes('5star'))  filterLabels.push('3726')

    const blackFridayImgGoods = await blackFridayImg.getBlackFridayImg({ 
      goods: goods,
      machineLabel: [...normalLabels, ...liveLabels, ...filterLabels],
      cart_goods_label,
    })

    const cartsItemMapper = cartInfo?.carts?.reduce((acc, item) => {
      acc[item.product.goods_id] = item
      return acc
    }, {})
    const realTimeLabels = []
    Object.values(blackFridayImgGoods).forEach((item) => {
      if (normalLabels.length) {
        item.showBehaviorLabel = BehaviorLabel.getSortedLabels(
          behaviorShow,
          item?.realTimeTspLabels,
          cartsItemMapper[item.goods_id],
          {
            LowestPriceDays: state.ABTParams?.LowestPriceDays?.param?.lowest_price_days == 'new',
          }
        ).map((v) => {
          return {
            ...v,
            isExposed: false, // 是否已曝光
          }
        })
      }
      const goods = cartsItemMapper[item.goods_id]
      if (liveLabels.length && goods && !state.soldoutIds.includes(goods?.id)) {
        const labels = BehaviorLabel.getSortedLabels(
          behaviorLive,
          item?.realTimeTspLabels,
          {},
          {
            LowestPriceDays: state.ABTParams?.LowestPriceDays?.param?.lowest_price_days == 'new',
          }
        ).map((v) => ({
          ...v,
          isExposed: false, // 是否已曝光
          goodsImg: goods?.product?.goods_img,
          goodsId: goods?.product?.goods_id,
        }))
        realTimeLabels.push(...labels)
      }
    })

    dispatch('fetchRealTimeLabelMemberInfo', realTimeLabels)

    commit('updateField', {
      key: 'blackFridayImgGoods',
      value: blackFridayImgGoods,
    })
  },
  // wiki.pageId=1018551397
  handleFlashSaleOverScene({ state }, { editCartId, carts, originCarts }) {
    let flashSaleOverLimit = state.flashSaleOverLimit || {}
    let originCartList = originCarts || []
    let lastCartItemIsOver = false // 编辑前是否超限
    let cartItemIsOver = false // 编辑后是否超限
    let flashSaleOverLimitTarget = flashSaleOverLimit[editCartId] || null
    if (flashSaleOverLimitTarget) {
      let originCartItem = originCartList.find((item) => item.id == editCartId)
      let cartItem = carts.find((item) => item.id == editCartId)
      if (originCartItem && cartItem) {
        lastCartItemIsOver =
          originCartItem.is_checked == 1 &&
          originCartItem.product.product_promotion_info?.some(
            (promo) => promo.single_over == 1 || promo.member_over == 1
          )
        cartItemIsOver =
          cartItem.is_checked == 1 &&
          cartItem.product.product_promotion_info?.some(
            (promo) => promo.single_over == 1 || promo.member_over == 1
          )
        if (
          (!lastCartItemIsOver && cartItemIsOver) ||
          (!flashSaleOverLimitTarget.alreadyShow &&
            lastCartItemIsOver &&
            cartItemIsOver)
        ) {
          // 非超限态到超限态或者超限态到超限态未弹窗过
          flashSaleOverLimitTarget.timeFn()
        } else if (lastCartItemIsOver && !cartItemIsOver) {
          // 超限态到非超限态 清状态缓存
          flashSaleOverLimitTarget.alreadyShow = false
          flashSaleOverLimitTarget.showStatus = false
        }
      }
    }
  },
  handleFlashSaleOverLimit({ state, getters }) {
    let flashSaleGroupList = getters.flashSaleGroupList || {}
    let flashSaleOverLimit = state.flashSaleOverLimit || {}
    let integrityPromotionInfo = state.integrityPromotionInfo || {}
    let flashSaleGroupListCarts = []
    Object.keys(flashSaleGroupList).forEach((groupListKey) => {
      if (integrityPromotionInfo[groupListKey]?.limit_mode == 1) {
        flashSaleGroupListCarts = flashSaleGroupListCarts.concat(
          flashSaleGroupList[groupListKey]
        )
      }
    })
    Object.keys(flashSaleOverLimit).forEach((cartId) => {
      if (!flashSaleGroupListCarts.find((cart) => cart.id == cartId)) {
        delete flashSaleOverLimit[cartId]
      }
    })
    flashSaleGroupListCarts.forEach((item) => {
      if (flashSaleOverLimit[item.id]) {
        flashSaleOverLimit[item.id].overType =
          item.normalFlashSale?.single_over == 1
            ? 'singleOver'
            : item.normalFlashSale?.member_over == 1
              ? 'memberOver'
              : ''
        flashSaleOverLimit[item.id].isChecked =
          item.is_checked == 1 ? true : false
      } else {
        flashSaleOverLimit[item.id] = {
          showType: getters.flashSaleOverShowType,
          overType:
            item.normalFlashSale?.single_over == 1
              ? 'singleOver'
              : item.normalFlashSale?.member_over == 1
                ? 'memberOver'
                : '',
          alreadyShow: false,
          showStatus: false,
          timmer: null,
          isChecked: item.is_checked == 1 ? true : false,
          timeFn: function () {
            this.alreadyShow = true
            // this.showStatus = true
            daEventCenter.triggerNotice({
              daId: this.overType == 'singleOver' ? '1-7-7-19' : '1-7-7-20',
            })
            if (
              this.overType == 'memberOver' ||
              (this.overType == 'singleOver' && this.showType == 'toast')
            ) {
              SMessage({
                message: state.language.SHEIN_KEY_PC_24348,
              })
              return
            }
            // if (this.timmer) window.clearTimeout(this.timmer)
            // this.timmer = setTimeout(() => {
            //   this.showStatus = false
            //   this.timmer = null
            // }, 5000)
          },
        }
      }
    })
  },
  handleFullGiftPromotionType({ state }) {
    promotionCenterNew.handleFullGiftType(state.integrityPromotionInfo)
  },
  async fetchCarts({ dispatch }, payload) {
    const res = await api.updateCart(payload)
    if (res.code == 0) {
      dispatch('asyncUpdateCartInfo', { cartInfo: res.info })
    }
  },
  async fetchIncludeTaxInfo({ commit }, payload) {
    const data = await getTaxTip(payload)
    if(data.code == 0) {
      commit('updateField', {
        key: 'taxInclusiveTip',
        value: data?.text || '',
      })
    }
  },

  // 获取附属品活动档位相关的附属商品
  async updatePromotionRuleList({}, { promotionInfo }) {
    if(typeof window == 'undefined') return
    const promotionList = Object.values(promotionInfo)
    let addBuyAct = promotionList.filter(item => [22, 13].includes(+item.type_id)) || []
    let addBuyActReq = []
 
    addBuyAct.forEach(promotion =>{
      let rulesLength = promotion.rules?.length || 0
      for (let i = 0; i <= rulesLength; i++) {
        let reqData = { promotion_id: promotion.promotion_id, range: i + 1 }
        addBuyActReq.push(
          schttp({
            url: '/api/cart/getAddBuyProductList/get',
            method: 'POST',
            data: reqData
          }).then((res) => {
            if (res.code == 0 && res.info) {
              promotion.rules[i].list = res.info.list || []
              promotion.rules[i].total = res.info.total || 0
            }
          }).catch(() => null)
        )
      }     
    })

    if (!addBuyActReq.length) return
    await Promise.all(addBuyActReq)
  },

  // 请求实时标签的用户信息
  async fetchRealTimeLabelMemberInfo({ commit }, payload) {
    if(typeof window == 'undefined') return
    if (!payload || !payload.length) return
    // const memberIds = Array.from(new Set(payload?.map((v) => v?.showLabel?.memberId)))
    // const res = await getMembersInfo(memberIds)
    // if (res.code != 0) return

    const labels = payload.map((v) => {
      let md5Str
      try {
        md5Str = md5(v?.showLabel?.memberId)
      } catch (err) {
        md5Str = 's***e'
      }
      return {
        ...v,
        nickName: `${md5Str[0]}***${md5Str[md5Str.length - 1]}`,
      }
    })

    commit('updateField', {
      key: 'realTimeBehaviorLabels',
      value: shuffleArr(labels),
    })
  },

  // 更新筛选栏标签
  handleCartFilterLabels({ commit, state, getters }) {
    // 获取购物车有效的行为标签（剔除售罄商品+剔除不在购物车中的商品）
    const soldoutGoodsIds = state.soldoutItems.map(s => s?.product?.goods_id)
    const cartGoodsIds = state.cartList.map(s => s?.product?.goods_id)
    const atomicProducts = {}
    Object.keys(state.blackFridayImgGoods).forEach((goodsId) => {
      if (cartGoodsIds.includes(+goodsId) && !soldoutGoodsIds.includes(+goodsId)) {
        atomicProducts[goodsId] = state.blackFridayImgGoods[goodsId]
      }
    })

    // 如果csc券接口保存，兜底走券助手接口
    if (state.couponLabelFromCsc === null) {
      commit('updateField', { key: 'cscCouponIsError', value: true })
    }

    commit('updateField', {
      key: 'goodsFilterLabels',
      value: composeFilterLabels(
        state.promotionLabels,
        state.couponFilterLabelData,
        getters.filterLabelOrder,
        getters.couponSelectType,
        state.language,
        atomicProducts,
        getters.moreFilterControl,
        state.locals,
        getters.couponFilterIsCsc,
        state.couponLabelFromCsc,
      )
    })

    // 筛选标签更新后，如果当前已选择的已不存在，则回到all状态
    const findLabel = state.goodsFilterLabels.find(
      v => !isNaN(state.filterType) ? v.id === state.filterType : v.type === state.filterType
    )
    if (!findLabel) {
      commit('updateField', { key: 'filterType', value: 'none' })
    }
  },

  // 聚合购物车利诱点
  handleCartInducementInfo({ commit, state }) {
    const abtArr = state.ABTParams?.IncentiveInform?.param?.IncentiveInformPop?.split(',') || []
    if (!abtArr.length || state.inducementInfoList?.length) return // 没配置abt or 已经有数据不更新了
    const normalCarts = state.cartList.filter(item => !state.soldoutIds.includes(item.id))
    const arr = []
    const { coupon_duration } = gbCommonInfo.CART_INDUCEMENT_LIMIT || {}
    const limitDuration = coupon_duration || (12 * 60 * 60 * 1000) // apollo配置毫秒, 默认12h

    // 根据abt先后顺序处理数据
    for (let i = 0; i < abtArr.length; i++) {
      // 即将售罄利诱
      if (abtArr[i] === 'oos') {
        const ossProducts = normalCarts?.filter(v => v.isIncentiveAlmostOosItem)
        if (ossProducts?.length) {
          let tips = ''
          if (ossProducts.length == 1) {
            tips = ossProducts[0].isShowOnlyXLeftBelt
              ? template(ossProducts[0].product?.real_stock, state.language['SHEIN_KEY_PC_31180'])
              : `${state.language['SHEIN_KEY_PC_31924']}<b style="color: #FA6338">${state.language['SHEIN_KEY_PC_31925']}</b>`
          } else {
            tips = template(ossProducts.length, state.language.SHEIN_KEY_PC_31143)
          }
          arr.push({
            type: 'oos',
            products: ossProducts.map(v => v.id),
            bubbleTips: tips,
            groupTitleText: state.language.SHEIN_KEY_PC_31189,
          })
        }
        continue
      }
      // 加车后降价
      if (abtArr[i] === 'mark_down') {
        const markDownProducts = normalCarts?.filter(v => v.reduce_price?.reduceAmount?.amount > 0)
        if (markDownProducts?.length) {
          const maxReduce = markDownProducts.reduce((acc, cur) => {
            return cur.reduce_price?.reduceAmount?.amount < acc.reduce_price?.reduceAmount?.amount ? acc : cur
          })
          const tips = template(maxReduce.reduce_price?.reduceAmount?.amount, state.language[markDownProducts.length == 1 ? 'SHEIN_KEY_PC_31182' : 'SHEIN_KEY_PC_31183'])
          arr.push({
            type: 'mark_down',
            products: markDownProducts.map(v => v.id),
            bubbleTips: tips,
            groupTitleText: state.language.SHEIN_KEY_PC_31190,
          })
        }
        continue
      }
      // 闪购
      if (abtArr[i] === 'flash_sale') {
        const sortPromotions = Object.values(state.integrityPromotionInfo)
          ?.filter((v) => v.type_id == 11 || (v.type_id == 10 && v.flash_type == '1'))
          ?.filter((v) => v.end_time * 1000 > Date.now() && v.end_time * 1000 - Date.now() < limitDuration)
          ?.sort((a, b) => a.end_time - b.end_time)
        if (sortPromotions?.length) {
          const flashProducts = normalCarts?.filter((item) => !!item.product?.product_promotion_info?.some(p => p.promotion_id == sortPromotions[0].promotion_id))
          const tips = flashProducts.length == 1 ? state.language.SHEIN_KEY_PC_31186 : template(flashProducts.length, state.language.SHEIN_KEY_PC_31187)
          arr.push({
            type: 'flash_sale', 
            promotionInfo: sortPromotions[0],
            products: flashProducts.map(v => v.id),
            bubbleTips: tips,
            groupTitleText: state.language.SHEIN_KEY_PC_31192,
            countDown: sortPromotions[0]?.end_time,
          })
        }   
        continue
      }
      // 到期优惠券
      if (abtArr[i] === 'coupon') {
        const sortCoupons = state.couponFilterLabelData?.usableCouponList
          ?.filter((v) => v.end_date * 1000 > Date.now() && v.end_date * 1000 - Date.now() < limitDuration)
          ?.sort((a, b) => a.end_date - b.end_date)
        if (sortCoupons?.length) {
          arr.push({
            type: 'coupon',
            couponInfo: sortCoupons[0],
            bubbleTips: state.language.SHEIN_KEY_PC_31188,
            countDown: sortCoupons[0]?.end_date,
          })
        } 
        continue
      }
      // 过去30天最低价：3864 | 过去30天最高价：4015 | 是否调价: 4018
      if (abtArr[i] === 'lowest_price_30days') {
        const thirtyLowestGoodIds = Object.values(state.blackFridayImgGoods)
          ?.filter(v => {
            const tsp = v?.realTimeTspLabels || {}
            if (isEmpty(tsp['3864']) || isEmpty(tsp['4015']) || isEmpty(tsp['4018'])) return false
            const cartItem = normalCarts.find(cItem => cItem.product?.goods_id === v.goods_id)
            if (!cartItem) return false
            const result = isThirtyLowestPrice(tsp, cartItem)
            const LowestPriceDays = state.ABTParams?.LowestPriceDays?.param?.lowest_price_days == 'new'
            return LowestPriceDays ? (result[30] || result[60] || result[90]) : result[30]
          }).map((v) => v.goods_id)
        const thirtyLowestProducts = normalCarts.filter(v => thirtyLowestGoodIds.includes(v.product?.goods_id))
        if (thirtyLowestProducts?.length) {
          if(state.ABTParams?.LowestPriceDays?.param?.lowest_price_days == 'new') {
            const XLowestPopoverTips = getXLowestPopoverTips(thirtyLowestProducts, state.blackFridayImgGoods, state.language)
            arr.push({
              type: 'lowest_price_30days',
              products: thirtyLowestProducts.map(v => v.id),
              bubbleTips: XLowestPopoverTips?.bubbleTips,
              groupTitleText: XLowestPopoverTips?.groupTitle,
              actualPointLowest: XLowestPopoverTips?.actualPointLowest,
            })
          } else {
            arr.push({
              type: 'lowest_price_30days',
              products: thirtyLowestProducts.map(v => v.id),
              bubbleTips: state.language[thirtyLowestProducts.length == 1 ? 'SHEIN_KEY_PC_31184' : 'SHEIN_KEY_PC_31185'],
              groupTitleText: state.language.SHEIN_KEY_PC_31191,
            })
          }
        }
        continue
      }
      const THRESHOLD = 10000
      if (abtArr[i] === '5star') {
        const cartIds = []
        let Stars = 0 // cache the stars for only one cartId
        for(let key in state.blackFridayImgGoods) {
          const value = state.blackFridayImgGoods[key]?.realTimeTspLabels?.['3726']
          if(value >= THRESHOLD) {
            let cartItem = normalCarts.find(i => i.product?.goods_id == key)
            if(cartItem) {
              cartIds.push(cartItem.id)
              Stars = floorToNearestBase(value, 1000)
            }
          }
        }
        if(cartIds.length) {
          let bubbleTips = cartIds.length == 1 ? template(Stars > 0 ? `${Stars}+` : '', state.language.SHEIN_KEY_PC_32267) : state.language.SHEIN_KEY_PC_32268
          arr.push({
            type: '5star',
            products: cartIds,
            bubbleTips,
            groupTitleText: template(cartIds.length, state.language.SHEIN_KEY_PC_32271),
          })
        }
      }
      if (abtArr[i] === '30day_sale') {
        const cartIds = []
        let Sales = 0
        for(let key in state.blackFridayImgGoods) {
          const value = state.blackFridayImgGoods[key]?.realTimeTspLabels?.['3451'] || 0
          if(value >= THRESHOLD) {
            let cartItem = normalCarts.find(i => i.product?.goods_id == key)
            if(cartItem) {
              cartIds.push(cartItem.id)
              Sales = floorToNearestBase(value, 1000)
            }
          }
        }
        if(cartIds.length) {
          let bubbleTips = cartIds.length == 1 ? template(Sales > 0 ? `${Sales}+` : '', state.language.SHEIN_KEY_PC_32265) : state.language.SHEIN_KEY_PC_32266
          arr.push({
            type: '30day_sale',
            products: cartIds,
            bubbleTips,
            groupTitleText: template(cartIds.length, state.language.SHEIN_KEY_PC_32270),
          })
        }
      }
      if (abtArr[i] === '30day_additem') {
        const cartIds = []
        let Adds = 0
        for(let key in state.blackFridayImgGoods) {
          const value = state.blackFridayImgGoods[key]?.realTimeTspLabels?.['3460']
          if(value >= THRESHOLD) {
            let cartItem = normalCarts.find(i => i.product?.goods_id == key)
            if(cartItem) {
              cartIds.push(cartItem.id)
              Adds = floorToNearestBase(value, 1000)
            }
          }
        }
        if(cartIds.length) {
          let bubbleTips = cartIds.length == 1 ? template(Adds > 0 ? `${Adds}+` : '', state.language.SHEIN_KEY_PC_32263) : state.language.SHEIN_KEY_PC_32264
          arr.push({
            type: '30day_additem',
            products: cartIds,
            bubbleTips,
            groupTitleText: template(cartIds.length, state.language.SHEIN_KEY_PC_32269),
          })
        }
      }
    }
  
    commit('updateField', {
      key: 'inducementInfoList',
      value: arr,
    })
    // 初始化利诱点类型顺序
    commit('updateField', {
      key: 'inducementSortTypes',
      value: arr.map((v) => v.type),
    })
  },
  // 更新利诱点类型顺序，每曝光一个，把最前面的移动到最后
  updateInducementSortTypes({ commit, state }) {
    if (!state.inducementSortTypes.length) return
    const tempTypes = state.inducementSortTypes
    const firstItem = tempTypes.shift()
    tempTypes.push(firstItem)
    
    commit('updateField', {
      key: 'inducementSortTypes',
      value: tempTypes,
    })
  },
  // 购物车利诱点排序
  sortCartInducementInfo({ commit, state }) {
    if (!state.inducementInfoList.length) return
    const sortedList = []
    state.inducementSortTypes.forEach(type => {
      const item = state.inducementInfoList.find(v => v.type === type)
      if (item) {
        sortedList.push(item)
      }
    })

    commit('updateField', {
      key: 'inducementSortList',
      value: sortedList,
    })
  },
  async getUseBffApi({ commit }, keys) {
    const value = await isSwitchBffApiVersion(keys)
    commit('updateField', { key: 'useBffApi', value })
  }
}
export default actions
