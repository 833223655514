<template>
  <LazyHydrationWrapper :when-triggered="isIdle">
    <PageHeader 
      container1350
      :locals="locals"
      :page-header-language="language"
    >
      <div class="new-cart-address">
        <cart-address />
      </div>
    </PageHeader>
    <NavBar
      :links="links"
      :active="0"
    >
      <template #notice>
        <div
          v-if="!showSkeleton"
          class="j-top-notice"
        >
          <cart-top-notice :cart-num="cartSumQuantity" />
        </div>
      </template>
    </NavBar>
  </LazyHydrationWrapper>
  <div
    :class="[
      showIncentiveProductListDialog ? 'incentive-list-mask' : ''
    ]"
  >
    <div
      class="container-fluid-1200 mcheck-cart j-cart-content"
      :class="!IS_RW && 'container-1350'"
    >
      <empty-cart-container
        v-show="!originCartNum"
        :is-real-show="!originCartNum"
      />
      <div
        v-show="originCartNum"
        class="row j-cart-list"
        :class="[IS_RW ? 'rw-cart-container__wrapper' : 'new-cart-container__wrapper']"
      >
        <div
          class=" c-check c-check__cart-left c-check-bag j-check-bag"
          :class="{'col-sm-8':originCartNum, 'new-cart-container__left': !IS_RW}"
        >
          <!-- 购物车全场提示 -->
          <div 
            class="j-top-tpl"
            :class="[IS_RW ? 'rw-cart-container__top' : 'cart-container__top']"
          >
            <cart-top-tip v-if="cartSumQuantity > 0 && !showSkeleton" />
            <!-- quickShip -->
            <client-only>
              <CartQuickShipTop v-if="showQuickShipOnTop" />
            </client-only>
          </div>
          <div
            class="j-shoppromo-tip-placeholder"
            style="height: 74px;display: none"
          ></div>

          <div
            v-show="originCartNum && !showSkeleton"
            class="j-shopbag-body"
          >
            <div class="j-vue-bag cart-list-wrap">
              <cart-list-vue
                :is-real-show="!!(originCartNum && !showSkeleton) && !cartSumQuantity"
                @render-end="scrollToTarget"
              />
            </div>
          </div>
        </div>
        <div
          v-show="originCartNum && !showSkeleton"
          class="j-shopbag-sum"
          :class="[IS_RW ? 'rw-cart-container__right' : 'new-cart-container__right']"
        >
          <div class="j-vue-price cart-container-summary">
            <summary-vue />
          </div>
        </div>
      </div>
    </div>
    <ClientOnly>
      <modal-vue
        :show="bigLoading"
        :twobtn="false"
        :has-close="false"
        :force="true"
        :ok-class="'she-hide'"
        background="transparent"
      >
        <template #footer>
          <div
            class="la-ball-pulse la-ball-pulse-2x la-ball-pulse-black"
          >
            <div></div><div></div><div></div>
          </div>
        </template>
      </modal-vue>
      
      <sold-out v-if="showSoldOutDialog" />

      <!-- 领券抽屉 -->
      <get-coupon-drawer v-if="cartList.length && drawerGetCoupon" />

      <!-- 凑单弹窗 -->
      <AddForCoupon v-if="addForCouponConfig.show" />
      <!-- 删除商品提示弹窗 -->
      <DeleteTips />
      <FullGiftModal v-if="asyncComponentLoadedStatus.cartFullGiftsDrawIsLoaded" />
      <AddBuyModal v-if="asyncComponentLoadedStatus.cartAddBuyDrawIsLoaded" />
      <BuyGiftModal v-if="asyncComponentLoadedStatus.cartBuyGiftDrawIsLoaded" />
      <ClubGiftModal v-if="asyncComponentLoadedStatus.cartClubGiftDrawIsLoaded" />
      <cart-batch-modal v-if="asyncComponentLoadedStatus.batchActiveIsLoaded" />
    </ClientOnly>
  </div>
</template>

<script>
import cartListVue from './components/list/cartList.vue'
import emptyCartContainer from './components/emptyCart/container.vue'

import summary from './components/summary.vue'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { assignAnalysis } from './utils/abt'
import sendPageView from './sendPv.js'
import { cartEmphasizePromotionAll, handleOrderOrSingleIsMeetOrOver, cartEmphasizePromotion } from './utils'
import { getQueryString } from '@shein/common-function'
import { defineAsyncComponent } from 'vue'
import { getCouponListForCart } from './utils/fetcher.js'
import { handlerCouponSaveToastByBff } from './utils/couponSaveToast.js'
import { getCouponDataForAddOnItem } from '@/public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/utils/couponData.js'
import BehaviorLabel from 'public/src/pages/cart_new/utils/behaviorLabel.js'
import { LazyHydrationWrapper } from '@shein-aidc/shein-lazy-hydration'
import { prefetchResource } from 'public/src/services/prefetchResource'
import { getAvailableCoupon } from 'public/src/pages/cart_new/utils/cartFilterLabels.js'
import { daEventCenter } from 'public/src/services/eventCenter'
import { detectUserInteraction, isTimestampWithinDuration } from './components/cartCheckoutIncentive/incentiveTools.js'
import { USE_BFF_API_KEYS } from './utils/constants'
import { web_cart_route_enter } from './utils/metricTagsConfig.js'
import UserInfoManager from 'public/src/services/UserInfoManager'

// 使用核心版本注册，不包含额外的模块
import { register } from 'swiper/element'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()

export default {
  name: 'CartContainer',
  components: {
    LazyHydrationWrapper,
    CartTopNotice: defineAsyncComponent(()=>import(/* webpackChunkName: "cart_top_part" */'./components/CartTopNotice.vue')),
    emptyCartContainer,
    cartListVue,
    soldOut: defineAsyncComponent(()=>import('./components/sold_out.vue')),
    cartTopTip: defineAsyncComponent(()=>import(/* webpackChunkName: "cart_top_part" */'./components/carttop/cartTopTip.vue')),
    cartAddress: defineAsyncComponent(()=>import(/* webpackChunkName: "cart_top_part" */'./components/cartAddress/index.vue')),
    'summary-vue': summary,
    getCouponDrawer: defineAsyncComponent(() => import('./components/getCoupon/drawer.vue')),
    AddForCoupon: defineAsyncComponent(()=>import(/* webpackChunkName: "cart-promotion-modal" */'./components/cartAddItem/indexNew.vue')),
    NavBar: defineAsyncComponent(()=>import(/* webpackChunkName: "cart_top_part" */'../components/nav-bar/NavBar.vue')),
    PageHeader: defineAsyncComponent(()=>import(/* webpackChunkName: "cart_top_part" */'../components/page-header/PageHeader.vue')),
    DeleteTips: defineAsyncComponent(()=>import('./components/deleteTips/DeleteTips.vue')),
    FullGiftModal: defineAsyncComponent(()=>import(/* webpackChunkName: "cart-promotion-modal" */'./components/promotion/FullGiftModal')),
    AddBuyModal: defineAsyncComponent(()=>import(/* webpackChunkName: "cart-promotion-modal" */'./components/promotion/AddBuyModal')),
    BuyGiftModal: defineAsyncComponent(()=>import(/* webpackChunkName: "cart-promotion-modal" */'./components/promotion/BuyGiftModal')),
    CartBatchModal: defineAsyncComponent(() => import(/* webpackChunkName: "cart-batch-modal" */'./components/CartBatchModal.vue')),
    ClubGiftModal: defineAsyncComponent(() => import(/* webpackChunkName: "cart-promotion-modal" */'./components/promotion/ClubGiftModal')),
    CartQuickShipTop: defineAsyncComponent(()=>import(/* webpackChunkName: "cart_top_part" */'./components/carttop/CartQuickShipTop.vue')),
  },
  data(){
    return {
      isIdle: false
    }
  },
  computed: {
    ...mapState([
      'originCartNum',
      'showSkeleton',
      'bigLoading',
      'asyncComponentLoadedStatus',
      'loginStatus',
      'isSSR',
      'locals',
      'cartlists',
      'mallInfo',
      'cartAbtInfo',
      'cartIntegrityPromotionInfo',
      'cartShippingFreeInfo',
      'blackFridayImgGoods',
      'realTimeBehaviorLabels',
      'couponInfo',
      'couponFilterLabelData',
      'inducementInfoList',
      'inducementSortList',
      'showIncentivePopover',
      'showIncentiveTimes',
      'incentivePopoverSwitchTimer',
      'showIncentiveProductListDialog',
      'batchActive',
      'language', 'cartSumQuantity', 'isPlatform', 'integrityPromotionInfo', 'ABTParams', 'shippingInfo', 'showSoldOutDialog', 'cartList', 'cartAddItemConfig', 'addForCouponConfig', 'drawerGetCoupon', 'filterType', 'cartInfo', 'targetCouponInfo', 'useBffApi', 'cartQsTagInfo', 'soldoutIds', 'couponLabelFromCsc']),
    ...mapGetters([
      'CouponAddonStatus',
      'couponAddon',
      'couponDiscount',
      'promotionNewAddSwitchV2',
      'incentiveTimeConfig',
      'couponSelectType',
      'showQuickShipTag',
      'couponFilterIsCsc'
    ]),
    hasAppendagePromotion(){
      return Object.values(this.integrityPromotionInfo).filter(item => [2, 4, 28, 13, 22].includes(+item.type_id))
    },
    // 导航栏选项
    links(){
      return [this.language.SHEIN_KEY_PC_14710, this.language.SHEIN_KEY_PC_14711, this.language.SHEIN_KEY_PC_14714, this.language.SHEIN_KEY_PC_14715]
    },
    IS_RW(){
      return this.locals.IS_RW
    },
    // 购物车顶部qs信息栏展示条件: 
    // 开启quickship标签展示abt && qs标签为新样式 && qs时效信息展示位置命中up && qs时效信息不为空 && 购物车有效商品行至少有一个是qs商品
    showQuickShipOnTop() {
      return this.showQuickShipTag
        && this.cartQsTagInfo?.isShowNewStyle
        && this.cartQsTagInfo?.qsTimelinessInfo?.isLocationTop
        && this.cartQsTagInfo?.qsTimelinessInfo?.tip
        && this.cartList.length > 0
        && this.cartList
          .filter(item => !this.soldoutIds.includes(item.id))
          .findIndex(item => item.quick_ship == 1) > -1
    }
  },
  watch: {
    cartList: {
      handler () {
        if(typeof window != 'undefined'){
          this.fetchCartCoupons()
        }
      },
      deep: true,
      immediate: true
    },
    couponFilterLabelData: {
      handler (val) {
        if(typeof window == 'undefined' || this.couponFilterIsCsc) return
        const couponLabels = getAvailableCoupon(val, this.language)
        daEventCenter.triggerNotice({
          daId: '1-7-3-22',
          extraData: {
            coupon_select: couponLabels?.length ? 1 : 0
          }
        })
      },
      immediate: true,
    },
    couponLabelFromCsc: {
      handler(val) {
        if(typeof window == 'undefined' || !this.couponFilterIsCsc) return
        const couponLabels = val?.userReceiveCouponList
        daEventCenter.triggerNotice({
          daId: '1-7-3-22',
          extraData: {
            coupon_select: couponLabels?.length ? 1 : 0
          }
        })
      },
      immediate: true
    },
  },
  created() {
    if(typeof window != 'undefined') {
      window.cart_prime_product_code = ''
    }
    this.updateField({ key: 'originCartNum', value: this?.cartlists?.cartSumQuantity || 0 })
    if(!this.isSSR){
      this.updateField({ key: 'showSkeleton', value: this?.cartlists?.cartSumQuantity || 0 })
    }
    
    this.updateMallInfo({ mallInfo: this.mallInfo })
    this.fetchABT()
    this.updateInfo({ info: this.cartlists }, true, this.isSSR).then( () => {
      if(typeof window != 'undefined') {
        this.sortCartInducementInfo()
        this.$nextTick(()=>{
          let $cartSkeleton = document.querySelector('.j-cart-skeleton')
          let $recommendList = document.querySelector('.j-recommend-list')
          $cartSkeleton && ($cartSkeleton.style.display = 'none')
          $recommendList && ($recommendList.style.visibility = 'visible')
          this.updateField({ key: 'showSkeleton', value: false })
        })
        this.fetchIncludeTaxInfo({
          siteUid: gbCommonInfo.SiteUID || '',
          lang: gbCommonInfo?.lang || '',
        })
        const getItemTypeNum = () => {
          return this.cartlists?.carts?.reduce((acc, item) => {
            if (!item.isSoldOutItem) {
              acc.row_count++
              if (item.isShowOnlyXLeftBelt) {
                acc.special_count++
              }
              if (item.isShowSoldOutBelt) {
                acc.almostsoldout++
              }
              if (item.isMallSwitchGoods) {
                acc.mallswitch++
              }
              if (item.isSaleAttrSwitchGoods) {
                acc.skuswitch++
              }
            }
            return acc
          }, {
            row_count: 0,
            special_count: 0,
            almostsoldout: 0,
            mallswitch: 0,
            skuswitch: 0,
          })
        }
        sendPageView(this.cartlists.cartSumQuantity, this.cartlists.mall_list || [], {
          label_id: this.filterType == 'none' ? 0 : this.filterType,
          all_row_count: this.cartlists?.carts?.length || 0,
          ...getItemTypeNum()
        })
        setTimeout(() => {
          this.handleIncentiveStart()
        }, 0)
      }
    })
  },
  provide() {
    return {
      openAddItem: this.openAddItem,
    }
  },
  beforeUnmount(){
    window.cart_prime_product_code = ''
  },
  mounted() {
    web_cart_route_enter()
    if ('scrollRestoration' in window.history){
      window.history.scrollRestoration = 'manual'
    }
    this.emphasize()
    window.TPM?.publish('viewcart', {
      carts: this.cartlists.carts,
      currency: this.cartlists?.env?.Currency,
      prices: { totalPrice: this.cartlists.totalPrice }
    })
    this.handleFirstScreenProducts()
    this.handlerCouponSaveToast()
    this.getUseBffApi(USE_BFF_API_KEYS)
    this.getCartQsTagInfo()

    daEventCenter.triggerNotice({
      daId: '1-7-3-22',
      extraData: {
        scenes: 'autopoint',
        max_available_point: this.cartInfo?.auto_use_points_info?.max_available_points || 0,
        auto_point_result: this.cartInfo?.auto_use_points_info?.auto_use_points > 0 ? 1 : 0,
        is_below_auto_use_point_threshold: this.cartInfo?.auto_use_points_info?.is_below_auto_use_point_threshold || 0,
      }
    })
    requestIdleCallback(() => {
      this.isIdle = true
      // 附属品弹窗延迟加载
      // if(this.hasAppendagePromotion.length) {
      prefetchResource.prefetchJs({
        prefetchList: [
          {
            chunkName: 'cart-promotion-modal',
            relType: 'prefetch'
          },
          {
            chunkName: 'cart-batch-modal',
            relType: 'prefetch'
          }
        ]
      })
      // }
    })
    /*
     * 产品暂时取消这部分埋点 wiki=1182724147
    this.$nextTick(() => {
      const { action } = this.cartInfo?.touch_rule_list?.find(i => i.hint_type == 5) || {}
      typeof action !== 'undefined' && daEventCenter.triggerNotice({
        daId: '1-7-3-22',
        extraData: {
          scenes: 'lower_price',
          action: action == 'null' ? 0 : action,
        }
      })
      window.addEventListener('beforeunload', (e) => {
        // 离开购物车时上报
        const { action } = this.cartInfo?.touch_rule_list?.find(i => i.hint_type == 5) || {}
        typeof action !== 'undefined' && daEventCenter.triggerNotice({
          daId: '1-7-3-22',
          extraData: {
            scenes: 'lower_price',
            action: action == 'null' ? 0 : action,
          }
        })
      })
    })
    */

    this.$nextTick(() => {
      window.addEventListener('beforeunload', () => {
        // 离开购物车时上报用户行为标签
        Object.values(this.blackFridayImgGoods).forEach((item) => {
          const baseLabels = BehaviorLabel.getNormalLabel(item.showBehaviorLabel) // 所有常规标签
          const labels = baseLabels.filter((v) => v.showLabelTag) // 根据abt实验展示的标签
          const labelList = labels.length == 0 ? baseLabels : labels
          if (baseLabels.length) {
            const exposedArr = labels?.filter((v) => v.isExposed)
            daEventCenter.triggerNotice({
              daId: '1-7-3-43',
              extraData: {
                userbehavior_tips: labelList?.map((v) => v.labelGear ? v.showLabel.flag ? v.labelGear[v.showLabel.flag] : v.labelGear['30'] : v.labelId).join(','),
                userbehavior_tips_show: exposedArr?.map((v) => v.labelGear ? v.showLabel.flag ? v.labelGear[v.showLabel.flag] : v.labelGear['30'] : v.labelId).join(','),
                goods_id: item.goods_id,
              }
            })
          }
        })

        // 上报实时标签
        if (this.realTimeBehaviorLabels.length) {
          const labels = this.realTimeBehaviorLabels.filter((v) => v.showLabelTag)
          const exposerLabels = labels?.filter((v) => v.isExposed)
          const labelList = labels.length == 0 ? this.realTimeBehaviorLabels : labels
          daEventCenter.triggerNotice({
            daId: '1-7-3-48',
            extraData: {
              userbehavior_tips: Array.from(new Set(labelList.map((v) => v.labelId))).join(','),
              userbehavior_tips_show: Array.from(new Set(exposerLabels.map((v) => v.labelId))).join(','),
            }
          })
        }
      })
    })
    window.cartCheckLogin = UserInfoManager.get({ key: 'UserInfo', options: { noCache: true }, actionType: 'cart/checkLogin' })
    document.addEventListener('visibilitychange', this.onvisibilitychange)
  },
  onBeforeUnmount(){
    document.removeEventListener('visibilitychange', this.onvisibilitychange)
  },
  methods: {
    ...mapActions(['asyncUpdateCartInfo', 'fetchIncludeTaxInfo', 'handleCartFilterLabels', 'getUseBffApi', 'sortCartInducementInfo', 'getCartQsTagInfo']),
    ...mapMutations(['updateLanguage', 'updateField', 'updateMallInfo', 'updateSkcBubblesCache', 'changeAppendageDrawerStatus', 'updateBottomActivityConfig']),
    /**
     * PromotionIcon 推全 ShowPromotionIcon
     */
    fetchABT() {
      if(typeof window != 'undefined') {
        assignAnalysis({ abtInfo: this.cartAbtInfo })
      }

      this.updateField({ key: 'ABTParams', value: this.cartAbtInfo })
    },
    async updateInfo(obj = {}, isFirst = false, isSSR = false) {
      await this.asyncUpdateCartInfo({ cartInfo: obj.info || {}, shippingInfo: isSSR ? this.cartShippingFreeInfo : null, isSSR })
      if(!isFirst){
        this.updateField({ key: 'originCartNum', value: true })
      }
    },
    openAddItem({ type, promotionId, fromType }) {
      if(['full-gift', 'add-buy', 'add-buy-all', 'buy-gift', 'club-gift'].includes(type)){
        this.changeAppendageDrawerStatus({ status: type, promotionId, fromType })
        return
      }
    },
    emphasize(){
      this.$nextTick(function() {
        //定位打折限购报错
        if ( getQueryString({ key: 'isDiscountLimit' }) || getQueryString({ key: 'isFlashLimit' })) {
          let LimitIds = []
          let promotionGroupList = this.cartList.filter(item => item.promotionGroupId)
          promotionGroupList.forEach( cartItem => {
            let result = handleOrderOrSingleIsMeetOrOver(cartItem, this.integrityPromotionInfo[cartItem.promotionGroupId], true)
            if(result && !LimitIds.includes(cartItem.promotionGroupId)){
              LimitIds.push(cartItem.promotionGroupId)
            }
          })

          if( LimitIds.length ){
            cartEmphasizePromotionAll(LimitIds, { shake: true, changeColor: true, scroll: true })
            return
          }
        }
      })
    },
    handleFirstScreenProducts(){
      if(document.documentElement.scrollTop > 10) {
        daEventCenter.triggerNotice({
          daId: '1-7-3-21',
          extraData: {
            products_count: '-'
          }
        })
        return
      }
      setTimeout(()=>{
        const documentOffsetHeight = document.body.offsetHeight
        let firstScreenProducts = 0
        document.querySelectorAll('.j-shopbag-body .cart-img').forEach((element) => {
          if (element.getBoundingClientRect().top + 15 <= documentOffsetHeight) {
            firstScreenProducts++
          }
        })
      
        daEventCenter.triggerNotice({
          daId: '1-7-3-21',
          extraData: {
            products_count: firstScreenProducts
          }
        })
      }, 800)
    },
    async fetchCartCoupons() {
      if(!this.loginStatus) {
        this.updateBottomActivityConfig({ couponIsReady: true })
        return
      }
      const res = await getCouponListForCart()
      if(res.code == 0) this.updateField({ key: 'couponInfo', value: res?.info || {} })
      this.updateCouponInfo() // 当商品信息发生变化，会触发券信息变化，需要更新targetCouponInfo
      this.updateBottomActivityConfig({ couponIsReady: true })
    },
    updateCouponInfo() {
      const currentCouponId = this.targetCouponInfo?.id
      if (!currentCouponId) return
      const { disabledCouponList, usableCouponList } = this.couponInfo
      const coupon = [...disabledCouponList, ...usableCouponList]
      const targetCoupon = coupon?.find(i => i.id == currentCouponId)
      if (targetCoupon?.id) {
        targetCoupon['couponType'] = targetCoupon.coupon_type_id == '1' 
          ? `coupon_money_${targetCoupon.coupon}`
          : `coupon_off_${targetCoupon.coupon}`   // 用于埋点
        this.updateField({ key: 'targetCouponInfo', value: targetCoupon || {} })
      } else {
        // 如果targetCoupon为空，说明这张券已经失效，需要重新更新登陆的券标签
        // console.log('--这张券已经失效', currentCouponId)
        this.$message({
          message: this.language.SHEIN_KEY_PC_25421,
          type: 'error'
        })
        this.updateField({ key: 'couponFilterLabelData', value: this.couponInfo || {} })
        this.handleCartFilterLabels()
        // 更新筛选栏标签后，选中状态重置为none，即取消筛选状态
        this.updateField({ key: 'targetCouponInfo', value: {} })
        this.updateField({ key: 'filterType', value: 'none' })
      }
    },
    handlerCouponSaveToast() {
      const couponData = getCouponDataForAddOnItem()
      handlerCouponSaveToastByBff({
        language: this.language,
        cartAbtInfo: this.ABTParams,
        cartInfo: this.cartInfo,
        couponInfo: couponData?.couponInfo,
      }, {
        $toast: this.$toast,
      })
    },
    scrollToTarget() {
    // wait cart list render end then try scroll to target promotion group header
      const promotionId = getQueryString({ key: 'promotionId' })
      if (promotionId) {
        cartEmphasizePromotion(promotionId, { scroll: true })
      }
    },
    onvisibilitychange() {
      const active = document.visibilityState !== 'hidden'
      if (active) {
        window.cartCheckLogin = UserInfoManager.get({ key: 'UserInfo', options: { noCache: true }, actionType: 'cart/checkLogin' })
      } else {
        window.cartCheckLogin = null
      }
    },
    handleIncentiveStart() {
      if(typeof window == 'undefined') return
      daEventCenter.triggerNotice({
        daId: '1-7-3-22',
        extraData: {
          benefitpop_available_point: this.inducementSortList.slice(0, 3).map(item => item.type).join(',') || '-',
          type: 'benefit_pop'
        }
      })
      if(this.ABTParams?.IncentiveInform?.param?.IncentiveInformPop == 'off') return
      if(this.inducementSortList.length < 1) return
      for(let i of this.inducementSortList) {
        if(!this.showIncentivePopover && i.countDown && isTimestampWithinDuration(i.countDown, this.incentiveTimeConfig?.leftCountDownTime)) {
          if(!this.showIncentivePopover) {
            this.updateField({
              key: 'showIncentivePopover',
              value: true
            })
          }
          return
        }
      }
      detectUserInteraction({
        noInteractionTimeout: this.incentiveTimeConfig?.noInteractionTimeout || 3000, // 单位为毫秒
        scrollThreshold: 2, // 屏幕高度的倍数
        onNoInteraction: () => {
          if(!this.showIncentivePopover) {
            this.updateField({
              key: 'showIncentivePopover',
              value: true
            })
          }
          // console.log('用户在首次进入3秒内没有交互。')
        },
        onScrollThresholdReached: () => {
          if(!this.showIncentivePopover && !this.batchActive && window.SaPageInfo.page_name == 'page_cart') {
            this.updateField({
              key: 'showIncentivePopover',
              value: true
            })
          }
          // console.log('用户滚动超过了两屏。')
        }
      })
    },
    handleGetCancelOrderInfo() {
      
    }
  }
}
</script>
<style lang="less">
@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-6px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {

        transform: translate3d(6px, 0, 0);
    }
}
.promotion-header-shake {
  animation-name: shake;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.cart-red-dark {
  color: @color_red_dark;
}
.mcheck-cart{
  padding-bottom: 0;
  margin: 0 auto;
}
.c-check__cart-left{
  padding-right: 10px;
  position: relative;
}
.c-check__cart-right{
  padding-left: 10px;
  border:none;
  &.c-summary-fixed{
    background: transparent;
    padding-top: 0;
  }
}
@media (min-width: 768px){
  .rw-cart-container {
    &__wrapper {
      display: flex;
      align-items: flex-start;
    }
    &__right {
      width: 33.33333333%;
      position: sticky;
      top: 0;
      .c-check__cart-right{
        width: 100%
      }
    }
    .rw-cart-container__top{
      position: sticky;
      top: 0;
      z-index: @zindex-header;
    }
  }
}
@media (min-width: 800px){
  .new-cart-container {
    &__right {
      position: sticky;
      top: 0;
    }
  }
  .cart-container__top{
    position: sticky;
    top: 0;
    z-index: @zindex-header;
    background: #f4f4f4;
  }
}

.new-cart-container {
  &__wrapper {
    display: flex;
    align-items: flex-start;
  }
  &__left {
    width: 66.66666667%;
  }
  &__right {
    width: 33.33333333%;
    // min-width: 320px;
    max-width: 450px;
  }
  // .col-sm-4{
  //   float: none!important;
  // }
}
/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  height: 44px;
  margin-top: -22px;
  z-index: @zindex-swiper-arrow;
  cursor: pointer;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev {
  left: 10px;
  right: auto;
}
.swiper-button-next {
  right: 10px;
  left: auto;
}
/* 定义关键帧动画 */
@keyframes highlight {
  /* 动画分四个阶段：从原始色到高亮色，再回到原始色，然后循环两次 */
  0%, 50%, 100% { background: #fff; }
  25%, 75% { background: #FFFBEE; }
}

/* 应用动画到具体目标元素上 */
.highlight-animate {
  /* 整个动画持续 800ms (400ms 高亮 * 2)，不延迟开始，执行一次 */
  animation: highlight 800ms linear 0s 1;
}
.sweep-light {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, transparent, rgba(255,255,255,.6), transparent);
    animation: sweepLight 6s linear infinite;
  }
}
@keyframes sweepLight {
  0% {
      transform: translate(-100%);
  }
  10% {
      transform: translate(100%);
  }
  100% {
      transform: translate(100%);
  }
}

</style>

<style lang="less" scoped>
// .cart-list-wrap{
//   margin-bottom: 18px;
// }
.new-cart-address { 
  flex: 1;
  overflow: hidden;
  display: flex;
}

.container-1350 {
  min-width: 800px;
  max-width: 1370px;
}
@media (min-width: 1366px) {
  .container-1350 {
    padding-left: 45px;
    padding-right: 45px;
    max-width: 1440px;
  }
}
.cart__down-time.active {
  .time-content{
    font-weight: bold!important; /* stylelint-disable-line declaration-no-important */
    /* stylelint-disable-next-line selector-max-specificity */
    .count-number{
      font-weight: bold!important; /* stylelint-disable-line declaration-no-important */
    }
  }
}
.incentive-list-mask {
  ::v-deep .c-modal-wrap {
    z-index: 400000;
  }
}
</style>
