<template>
  <span
    v-if="onlyShowUnitPrice"
    class="goods-price-red"
  >
    {{ unitPrice.amountWithSymbol }}
  </span>
  <span
    v-else
    :class="priceCls"
  >
    <div class="price-wrap">
      <!-- 促销icon -->
      <cartListItemIcon
        class="promotion-price-icon"
        prime-icon-size="16px"
        :item="item"
        :top="1"
        :is-premium-member="isPremiumMember"
        :prime-icon-v2="cartDiscountLogoA"
        :is-suggested="isSuggested"
      />
      <template v-if="!locals.IS_RW">
        <em
          v-if="isVipItem"
          :class="[locals.GB_cssRight ? 'cart-vip__tag__ar' : 'cart-vip__tag']"
          class="cart-vip__tag-align"
        ></em>
        <span v-if="showType == 'text'">
          {{ showBeforeCouponPrice ? beforeCouponPrice.amountWithSymbol : unitPrice.amountWithSymbol }}
        </span>
        <number-roll
          v-else
          class="price-number-roll"
          :amount="showBeforeCouponPrice ? beforeCouponPrice.amountWithSymbol : unitPrice.amountWithSymbol"
          :box-width="9"
          :box-height="18"
        />
      </template>
      <template v-else>
        <romwe-vip-price
          v-if="isVipItem"
          :price="unitPrice.amountWithSymbol"
        />
        <span v-else-if="showType == 'text'">
          {{ unitPrice.amountWithSymbol }}
        </span>
        <number-roll
          v-else
          class="price-number-roll"
          :amount="unitPrice.amountWithSymbol"
          :box-width="9"
          :box-height="18"
        />
      </template>
      <template
        v-if="showDelPrice || (item.product.insurable == 1 && item.product.sale_price_before_insured)"
      >
        <span
          v-if="showType == 'text' || !config.hideRetailPrice"
          class="crossed-price-item"
          :title="delPriceText"
          v-html="delPriceText"
        ></span>
        <SPopover
          v-if="isSuggested == 'special_de' && [3].includes(item.suggestedPriceType)"
          :content="language.SHEIN_KEY_PC_31070"
          trigger="hover"
          theme="dark"
          :show-close-icon="true"
          :outside-close="false"
        >
          <template #reference>
            <span class="lowest-price-tip">
              <sui_icon_info_12px_1 size="12px" />
            </span>
          </template>
        </SPopover>
      </template>
    </div>
    <!-- 到手价及倒计时 -->
    <EstimatedTag
      v-if="!hideEstimated && showEstimatedTag"
      fix-disappear-position
      :item="item"
      :language="language"
      :cart-abt-info="ABTParams"
      :css-right="locals.GB_cssRight"
      :is-batch-active-page="isBatchActivePage"
      :is-show-cartrow-estimated-countdown="isShowCartrowEstimatedCountdown"
      :cart-coupon-infos="cartCouponInfos"
      :integrity-promotion-info="integrityPromotionInfo"
      :flash-sale-group-list="flashSaleGroupList"
      :cart-info="cartInfo"
      :abt-info="{
        primeIconV2: cartDiscountLogoA
      }"
      :is-suggested="isSuggested"
      :show-discount="showDiscountOnEstimated"
      :discount-text="discountText"
    />
    <!-- 折扣标签及倒计时 -->
    <DiscountTag
      v-if="showDiscountTag"
      fix-disappear-position
      :item="item"
      :language="language"
      :discount-text="discountText"
      :is-batch-active-page="isBatchActivePage"
      :cart-abt-info="ABTParams"
      :css-right="locals.GB_cssRight"
      :is-show-cartrow-discount-countdown="showCartPromotionsDetail"
      :integrity-promotion-info="integrityPromotionInfo"
      :flash-sale-group-list="flashSaleGroupList"
      :cart-info="cartInfo"
      :abt-info="{
        primeIconV2: cartDiscountLogoA
      }"
      :is-suggested="isSuggested"
    />
  </span>
</template>

<script>
import cartListItemIcon from '../cartListItemIcon.vue'
import RomweVipPrice from 'public/src/pages/components/productItem/components/RomweVipPrice.vue'
import { mapGetters, mapState } from 'vuex'
import EstimatedTag from 'public/src/pages/cart_new/components/EstimatedTag/index.vue'
import DiscountTag from 'public/src/pages/cart_new/components/DiscountTag/index.vue'
import { template } from '@shein/common-function'
import { sui_icon_info_12px_1 } from '@shein-aidc/icon-vue3'


export default {
  name: 'CartItemPriceSpan',
  components: {
    cartListItemIcon,
    RomweVipPrice,
    EstimatedTag,
    DiscountTag,
    sui_icon_info_12px_1
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    showType: {
      type: String,
      default: 'interactive'
    },
    config: {
      type: Object,
      default: () => ({})
    },
    isBatchActivePage: {
      type: Boolean,
      default: false,
    },
    hideEstimated: Boolean,
    fromScene: {
      type: String,
      default: ''
    },
    showBeforeCouponPrice: {
      type: Boolean,
      default: false,
    },
    showEstimatedTag: {
      type: Boolean,
      default: false,
    },
    showDiscountOnEstimated: {
      type: Boolean,
      default: false,
    },
    showDiscountTag: {
      type: Boolean,
      default: false,
    },
    discountText: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['language', 'ABTParams', 'soldoutIds', 'locals', 'integrityPromotionInfo', 'cartInfo']),
    ...mapGetters(['isShowCartrowEstimatedCountdown', 'cartCouponInfos', 'cartDiscountLogoA', 'flashSaleGroupList', 'isSuggested', 'showCartPromotionsDetail' ]),
    isVipItem(){
      return this.item.promotionTypeIds?.includes(12)
    },
    isPremiumMember(){
      return this.item.promotionTypeIds.includes(29) || this.item.isSheinClubGiftItem
    },
    unitPrice(){
      return this.item.product?.feSalePrice
    },
    beforeCouponPrice() {
      return this.item.unit_price_before_coupon
    },
    promotionCutPriceShow () {
      const item = this.item
      if(item && item.reduce_price && this.soldoutIds.indexOf(item.id) == -1) {
        return Object.keys(item.reduce_price).length > 0 && item.reduce_price.siteFrom == this.locals.SiteUID && item.reduce_price.reduceAmount.amount > 0
      } else {
        return false
      }
    },
    delPriceText() {
      if(this.item.product.insurable == 1 && this.item.product.sale_price_before_insured){
        return `<em>${this.item.product.sale_price_before_insured.amountWithSymbol}</em>`
      }
      if(this.isSuggested == 'special_de') {
        if([1].includes(this.item.suggestedPriceType)) {
          return `<em>${this.item.product.retailPrice.amountWithSymbol}</em>  <em>${this.item.product.suggested_sale_price.amountWithSymbol}</em>  ${this.language.SHEIN_KEY_PC_31069}`
        } else if([3].includes(this.item.suggestedPriceType)) {
          return `<em>${this.item.product.retailPrice.amountWithSymbol}</em>`
        } else if([5].includes(this.item.suggestedPriceType)) {
          return `<em>${this.item.product.suggested_sale_price.amountWithSymbol}</em>  ${this.language.SHEIN_KEY_PC_31069}`
        }
        return  ''
      }
      return  this.isSuggested == 'special' ? template(`<em>${this.item.product.feOriginalPrice.amountWithSymbol}</em>`, '{0} ' + this.language.SHEIN_KEY_PC_30549) : `<em>${this.item.product.feOriginalPrice.amountWithSymbol}</em>`
    },
    // is_inversion是否倒挂，仅fr/pl站点合规展示 pageId=1360565216
    showDelPrice(){
      if(!this.isSuggested) return +this.unitPrice.amount < +this.item.product.feOriginalPrice.amount
      if(this.isSuggested == 'special_de') {
        return  [1, 3, 5].includes(this.item.suggestedPriceType)
      }
      return this.item.is_inversion == 1 ? false : +this.unitPrice.amount < +this.item.product.feOriginalPrice.amount
    },
    isDiscountProduct(){
      let special_de_price = this.isSuggested == 'special_de' ? this.unitPrice.amountWithSymbol !== this.item.product.suggested_sale_price.amountWithSymbol : false
      return !(this.isVipItem || this.isPremiumMember) && (special_de_price || this.unitPrice.amountWithSymbol !== this.item.product.feOriginalPrice.amountWithSymbol || this.promotionCutPriceShow || this.item.product.insurable == 1)
    },
    priceCls() {
      const res = ['goods-price-red']
      if (this.isSuggested && this.isSuggested != 'special_de' && this.item.is_inversion && !this.item.isPromotionB && !this.item.isSheinClubItem) return res 
      if(this.isSuggested == 'special_de' && ![1, 3, 5].includes(this.item.suggestedPriceType)) return res
      if (this.isDiscountProduct) { // 折扣价格类型
        res.push('price-discount')
      } else {
        if(this.isVipItem) {
          res.push('price-vip')
        }
        if (this.isPremiumMember) { // 付费会员专享类型
          res.push(this.cartDiscountLogoA ? 'a-premium-vip' : 'premium-vip')
        }
      }
      return res
    },
    // 合规站点非商品行仅展示黑色价格
    onlyShowUnitPrice() {
      return this.isSuggested && this.fromScene !== 'cartLine'
    } 
  }
}
</script>

<style lang="less" scoped>
@s3_exclusive: '/she_dist/images/sheinvip2/s3_exclusive-3286f50076.png';
@s3_exclusive_ar: '/she_dist/images/sheinvip2/s3_exclusive_ar-7c299f990d.png';
.goods-price-red{
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  // height: 20px;
  //color: #FF411C;
  &.price-discount{
    color: @sui_color_discount;
  }
  &.premium-vip{
    color: #C96E3F;
  }
  &.a-premium-vip {
    color: #873C00;
  }
  &.price-vip{
    color: #A78A45;
    .vip-tag{
      vertical-align: baseline;
      width: 36px;
    }
  }
  .crossed-price-item{
    font-weight: normal;
    font-size: 12px;
    margin-left: 2px;
    color: @sui_color_gray_dark3;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    position: relative;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    :deep {
      em {
        text-decoration: line-through;
      }
    } 
  }
  .lowest-price-tip{
    color: @sui_color_gray_dark3;
    margin-left: 2px;
    cursor: pointer;
  }
  .tip-red{
    i{
      font-size: 16px;
    }
  }
  .promotion-price-icon > :last-child {
    margin-right: 2px;
  }
  .cart-vip__tag-align {
    align-self: center;
    margin-right: 2px;
  }
  /deep/.price-number-roll {
    flex-shrink: 0;
    display: inline-flex;
    align-items: flex-end;
    .qw-num-box {
      top: 0!important;
    }
  }
  .cart-vip__tag, .cart-vip__tag-ar {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 34px 10px;
    width: 34px;
    height: 10px;
  }
  .cart-vip__tag {
    background-image: url(@s3_exclusive);
  }
  .cart-vip__tag-ar {
    background-image: url(@s3_exclusive_ar);
    vertical-align: -1px;
  }
  /deep/ .estimated-tag,
  /deep/ .discount-tag {
    margin-top: 4px;
  }
  .price-wrap {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: baseline;
    margin-right: 4px;
  }
}
</style>
